var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative text-left"},[(_vm.label)?_c('label',{staticClass:"font-semibold text-xs tracking-widest uppercase transition-colors duration-200 ease-out",class:[
           {'text-gray-200': !_vm.focused},
           {'text-white': _vm.focused},
           {'text-red-500': _vm.errors && _vm.errors.length},
         ],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),_c('div',{staticClass:"input-wrapper",class:[
         {'input-wrapper--focused': _vm.focused},
         {'input-wrapper--black': _vm.hasBlackCorner}
       ]},[_c('input',{ref:"input",staticClass:"input w-full text-white bg-gray-800 text-base placeholder-gray-400 px-4 py-3 border-b border-gray-400 focus:outline-none",class:[
          {'input--filled': _vm.value && _vm.value.length > 0},
          {'disabled': _vm.disabled},
          {'border-red-600': _vm.errors && _vm.errors.length},
        ],attrs:{"autocomplete":"disabled","autocorrect":"false","spellcheck":"false","id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder,"maxlength":_vm.maxLength,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue()},"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('enter')}}})]),(_vm.errors && _vm.errors.length)?_c('div',_vm._l((_vm.errors),function(error,index){return _c('div',{key:index,staticClass:"text-red-500 text-sm pl-1"},[_vm._v(" "+_vm._s(error)+" ")])}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }