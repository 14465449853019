import api from '../store/api';
import router from '../router';

export default function setup(_this) {  // eslint-disable-line
  api.interceptors.request.use((response) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) response.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`;

    const incrementRequestCount = Object.keys(response).includes('incrementRequestCount') ? response.incrementRequestCount : true;
    if (incrementRequestCount) {
      _this.$store.commit('UPDATE_PENDING_REQUEST_COUNT', 1, { root: true });
    }

    return response;
  });

  api.interceptors.response.use((response) => {
    const incrementRequestCount = Object.keys(response.config).includes('incrementRequestCount') ? response.config.incrementRequestCount : true;

    if (incrementRequestCount) {
      _this.$store.commit('UPDATE_PENDING_REQUEST_COUNT', -1, { root: true });
    }
    return response;
  }, (error) => {
    const incrementRequestCount = Object.keys(error.config).includes('incrementRequestCount') ? error.config.incrementRequestCount : true;

    if (incrementRequestCount) {
      _this.$store.commit('UPDATE_PENDING_REQUEST_COUNT', -1, { root: true });
    }

    if (error.response.status !== 401
      && error.response.data
      && !error.response.data.message
      && !error.response.data.token) {
      const errors = [];
      for (const fieldName in error.response.data) {  // eslint-disable-line
        if (error.response.data[fieldName].length > 0) {
          let errorString = '';
          if (Array.isArray(error.response.data[fieldName])) {
            errorString = `${error.response.data[fieldName][0]}`;
            errors.push({
              fieldName,
              message: errorString,
            });
          } else {
            errorString = error.response.data[fieldName];
            errors.push({
              message: errorString,
            });
          }
        }
      }
      _this.$store.commit('UPDATE_GLOBAL_ERRORS', errors);
      return Promise.reject(error);
    }

    if (error.response.status === 401) {
      _this.$store.commit('CLEAR_STATE');
      localStorage.clear();
      router.push({ name: 'login' });
      return Promise.reject(error);
    }

    _this.$store.commit('UPDATE_GLOBAL_ERRORS', ['Your request failed. Try again later.']);
    return Promise.reject(error);
  });
}
