export default {
  watch: {
    idTokenPayload(idTokenPayload) {
      if (idTokenPayload.accessToken) {
        this.$store.dispatch('validateOrganization', idTokenPayload);
      }
    },
    organizationIsInactive(organizationIsInactive) {
      if (organizationIsInactive) this.$router.push({ name: 'inactive-organization' });
    },
  },
};
