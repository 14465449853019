<template>
  <section class="fixed inset-0 flex items-center justify-center z-50"
           @click="closeSubscriptionModal">
    <div  class="bg-black p-6 rounded-lg shadow-lg flex-col items-center justify-center gap-4"
         @click.stop>
      <h2 class="px-6 content-center">Subscription Options</h2>
      <div v-if="prices" class="bg-black  rounded-lg shadow-lg
       justify-center items-stretch flex flex-row flex-wrap gap-6 p-6 max-w-3xl">
        <SubscribeButton :price="yearly" :interval="1" label="Yearly"/>
        <SubscribeButton :price="sixMonths" :interval="2" label="Six Month"/>
        <SubscribeButton :price="quarterly" :interval="4" label="Quarterly"/>
        <SubscribeButton :price="monthly" :interval="12" label="Monthly"/>
      </div>
      <div class="px-6 content-center" v-else >
        Our prices are based on the number of hosts we find.
        Once you run a report you will be able to find prices here.
      </div>
      <p v-if="prices" class="px-6 content-center">
        Please allow the invoice to be created. It can take up to 20 seconds.
      </p>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import SubscribeButton from '@/components/SubscribeButton.vue';
import modalMixin from '@/mixins/ModalMixin';

export default {
  name: 'SubscriptionModal',
  components: { SubscribeButton },
  mixins: [modalMixin],
  computed: {
    ...mapGetters(['prices']),
    yearly() {
      return this.prices.yearly.toLocaleString();
    },
    sixMonths() {
      return this.prices.sixMonth.toLocaleString();
    },
    quarterly() {
      return this.prices.quarterly.toLocaleString();
    },
    monthly() {
      return this.prices.monthly.toLocaleString();
    },
  },
  methods: {
    ...mapActions(['closeModal']),
    closeSubscriptionModal() {
      this.closeModal();
      this.close();
    },
  },
};
</script>
<style scoped></style>
