import authentication from './authentication';
import error from './error';
import report from './report';
import rules from './rules';
import subscription from './subscription';

export default {
  authentication,
  error,
  report,
  rules,
  subscription,
};
