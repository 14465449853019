import api from '@/store/api';

const state = {
  modalOpen: false,
  prices: null,
};

const getters = {
  modalOpen: ({ modalOpen }) => modalOpen,
  prices: ({ prices }) => prices,
};
const mutations = {
  UPDATE_MODAL_OPEN: (state, payload) => {
    state.modalOpen = payload;
  },
  UPDATE_PRICES: (state, payload) => {
    state.prices = payload;
  },
};

const actions = {
  fetchPrices: async ({ commit }) => {
    try {
      const pricesRequest = await api.get('/prices/');
      commit('UPDATE_PRICES', pricesRequest.data);
      commit('UPDATE_MODAL_OPEN', true);
    } catch (e) {
      commit('UPDATE_PRICES', null);
    }
  },
  openModal: ({ commit }) => {
    commit('UPDATE_MODAL_OPEN', true);
  },
  closeModal: ({ commit }) => {
    commit('UPDATE_MODAL_OPEN', false);
  },
  getSubscriptionUrl: async ({ commit }, period, target = '_self') => {
    commit('UPDATE_IS_LOADING', true, { root: true });
    try {
      const response = await api.post('/create-subscription/', { period });
      window.open(response.data.url, target);
    } catch (error) {
      commit('UPDATE_GLOBAL_ERRORS', [error.description]);
    } finally {
      commit('UPDATE_IS_LOADING', false, { root: true });
    }
  },
};
export default {
  state,
  getters,
  mutations,
  actions,
};
