<template>
  <div id="app"
       class="flex flex-col min-h-screen">
    <Header />
    <router-view/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Header from '@/components/Header.vue';
import LoginUserMixin from '@/mixins/LoginUserMixin';
import interceptor from './interceptors';

export default {
  name: 'App',
  components: {
    Header,
  },
  mixins: [LoginUserMixin],
  computed: {
    ...mapGetters(['isAuthenticated', 'reports']),
  },
  created() {
    interceptor(this);
    this.checkAuthentication();
  },
  async mounted() {
    this.$root.$on('findingsFilter', ($evt) => {
      this.$store.dispatch('addToFilterFinding', $evt);
    });
  },
  methods: {
    ...mapActions(['logout', 'checkAuthentication', 'fetchCurrentUser', 'fetchReports']),
  },
  watch: {
    async isAuthenticated(isAuthenticated) {
      if (isAuthenticated) {
        await this.fetchCurrentUser();
        await this.fetchReports();
      }
    },
  },
};
</script>
<style lang="css">
@media print {
  body {
    display: block !important;
  }
}
</style>
