export default {
  bind(el, binding, vNode) {
    el.vueClickOutside = (event) => {
      if (!el.contains(event.target)) {
        // This assumes that the argument passed to this directive [binding.arg] is a boolean in the
        // component's data object [vNode.context]
        if ((binding.arg && vNode.context[binding.arg]) || !binding.arg) {
          // Call method provided in v-click-outside value
          vNode.context[binding.expression](event);
          event.stopPropagation();
        }
      }
    };
    document.body.addEventListener('click', el.vueClickOutside);
  },
  unbind(el) {
    // Remove event listeners
    document.body.removeEventListener('click', el.vueClickOutside);
    el.vueClickOutside = null;
  },
};
