<template>
  <button :disabled="disabled || inFlight"
          @click.stop="click"
          class="inline-block font-semibold tracking-wider text-sm relative text-center
                 appearance-none cursor-pointer uppercase
                 focus:outline-none
                 transition-colors duration-100 ease-in"
          :class="[
            { primary: type === 'primary' && !disabled },
            { text: type === 'text' && !disabled },
            { 'is-loading': inFlight },
            { 'text-small': type === 'text-small' && !disabled },
            { 'secondary': type === 'secondary' && !disabled },
            { 'secondary-orange': type === 'secondary-orange' && !disabled },
            { 'secondary-blue': type === 'secondary-blue' && !disabled },
            { disabled },
          ]">
    <div class="flex items-center justify-center pointer-events-none"
         :class="{ 'opacity-0': inFlight }">
      <div v-if="hasIconLeft" class="mr-2">
          <slot name="iconLeft"></slot>
      </div>
      <slot><!-- Button Text --></slot>
      <div v-if="hasIconRight" class="ml-2">
          <slot name="iconRight"></slot>
      </div>
    </div>
    <div v-if="inFlight" class="loading">
      <span></span>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    /**
     * Determines what type of button this should be visually
     */
    type: {
      type: String,
      validator: (type) => [
        'primary',
        'text',
        'text-small',
        'secondary',
        'secondary-orange',
        'secondary-blue',
      ].indexOf(type) !== -1,
      required: true,
    },
    /**
     * Whether or not the button should be disabled
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Some buttons should react correctly to asynchronous actions. This will set the inflight state
     * correctly. Currently disabling, but eventually it will also display the correct animation
     */
    inFlight: Boolean,
  },
  computed: {
    hasIconLeft() {
      return this.$slots.iconLeft;
    },
    hasIconRight() {
      return this.$slots.iconRight;
    },
  },
  methods: {
    click() {
      this.$emit('onClick');
    },
  },
};
</script>
<style scoped>
  button {
    &:disabled {
      @apply bg-gray-700 cursor-default;

      &:hover, &:focus {
        @apply shadow-none;
      }
    }
  }

  .primary {
    @apply relative bg-orange-500 text-white z-0 px-6 py-3;

    &::after {
       @apply absolute -z-1 shadow-gloworange opacity-0 transition-opacity ease-out duration-200
       top-0 left-0 w-full h-full;
       content: '';
     }

    &:hover, &:focus {
      &::after {
        @apply opacity-100;
      }
    }

    &:active {
      @apply bg-orange-600;
      &::after {
        @apply opacity-0;
      }
    }
  }

  .secondary {
    @apply relative bg-gray-800 text-white z-0 border px-6 py-3;

    &::after {
       @apply absolute -z-1 shadow-glowblue100 opacity-0 transition-opacity ease-out duration-200;
       width: calc(100% + 4px);
       height: calc(100% + 4px);
       top: -2px;
       left: -2px;
       content: '';
     }

    &:hover, &:focus {
      @apply border-blue-100 bg-blue-800 text-white;

      &::after {
         @apply opacity-100;
      }
    }

    &:active {
      @apply border-blue-100 bg-blue-800 text-white;
      &::after {
         @apply opacity-0;
       }
    }
  }

  .secondary-orange {
    @apply relative bg-gray-900 text-white z-0 border-2 border-orange-400 px-6 py-3;

    &::after {
       @apply absolute -z-1 shadow-gloworange400 opacity-0 transition-opacity ease-out duration-200;
       width: calc(100% + 4px);
       height: calc(100% + 4px);
       top: -2px;
       left: -2px;
       content: '';
     }

    &:hover, &:focus {
      @apply bg-gray-700;
      &::after {
        @apply opacity-100;
      }
    }

    &:active {
      @apply bg-gray-900;
      &::after {
         @apply opacity-0;
       }
    }
  }

  .secondary-blue {
    @apply relative bg-gray-900 text-white z-0 border-2 border-blue-100 px-6 py-3;

    &::after {
      @apply absolute -z-1 shadow-glowblue100 opacity-0 transition-opacity ease-out duration-200;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      top: -2px;
      left: -2px;
      content: '';
    }

    &:hover, &:focus {
      @apply bg-gray-700;
      &::after {
        @apply opacity-100;
      }
    }

    &:active {
      @apply bg-gray-900;
      &::after {
        @apply opacity-0;
      }
    }
  }

  .text {
    @apply bg-transparent px-6 py-3;

    &:hover, &:focus {
      @apply text-gray-200;
    }

    &:active {
      @apply text-gray-300;
    }
  }

  .text-small {
    @apply bg-transparent text-xs;

    &:hover, &:focus {
      @apply text-gray-200;
    }

    &:active {
      @apply text-gray-300;
    }
  }

  .disabled {
    @apply cursor-default bg-gray-600 text-gray-900 px-6 py-3;

    &:hover {
      @apply bg-gray-600 text-gray-900;
    }
  }

  .loading {
    @apply absolute top-0 left-0 flex items-center justify-around w-full h-full px-3;
    & span {
      @apply inline-block opacity-0 w-2 h-2 bg-orange-400 shadow-gloworange;

      &:nth-child(1) {
        animation: opacityPulse 2s ease-in-out infinite;
      }
      &:nth-child(2) {
        animation: opacityPulse 2s ease-in-out 1s infinite;
      }
      &:nth-child(3) {
        animation: opacityPulse 2s ease-in-out infinite;
      }
    }
  }

  @keyframes opacityPulse {
    0%, 100%{
      @apply opacity-100;
    }
    50%{
      @apply opacity-0;
    }
  }
</style>
