export default {
  mounted() {
    this.open();
  },
  methods: {
    open() {
      this.$root.$el.style['overflow-y'] = 'hidden';
      this.$root.$el.style.height = '100vh';
    },
    close(redirectTo, shouldFetchReport) {
      this.$root.$el.style['overflow-y'] = '';
      this.$root.$el.style.height = '';
      this.$emit('close', redirectTo, shouldFetchReport);
    },
  },
};
