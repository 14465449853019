const state = {
  globalErrors: [],
  fieldErrors: {},
};

const getters = {
  globalErrors: ({ globalErrors }) => globalErrors,
  fieldErrors: ({ fieldErrors }) => (fieldErrors),
};

const mutations = {
  CLEAR_ERRORS: (state, payload) => {
    if (!payload) return;

    state.globalErrors = [];
  },
  UPDATE_GLOBAL_ERRORS: (state, payload) => {
    state.globalErrors = payload;
  },
  CLEAR_FIELD_ERRORS: (state, payload) => {
    if (!payload) return;

    state.fieldErrors = {};
  },
  UPDATE_FIELD_ERRORS: (state, payload) => {
    state.fieldErrors = payload;
  },
};

const actions = {
  clearGlobalErrors: ({ commit }) => {
    commit('CLEAR_ERRORS', true);
  },
  clearFieldErrors: ({ commit }) => {
    commit('CLEAR_FIELD_ERRORS', true);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
