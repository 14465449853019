import api from '../api';

const state = {};

const getters = {};

const mutations = {};

const actions = {
  // eslint-disable-next-line no-unused-vars
  updateFindingVisibility: ({ commit }, { id, visible }) => api.patch(`findings/${id}/`, {
    visible,
  }, {
    incrementRequestCount: false,
  })
    .then(({ data }) => data)
    .catch(() => {})
    .finally(() => {}),

  // eslint-disable-next-line no-unused-vars
  updateAttackPathwayVisibility: ({ commit }, { id, visible }) => api.patch(`attack_pathway_findings/${id}/`, {
    visible,
  }, {
    incrementRequestCount: false,
  })
    .then(({ data }) => data)
    .catch(() => {})
    .finally(() => {}),
};

export default {
  actions,
  state,
  getters,
  mutations,
};
