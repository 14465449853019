import Vue from 'vue';

import Tooltip from 'vue-directive-tooltip';
import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css';
import VueMasonry from 'vue-masonry-css';

import App from './App.vue';
import router from './router';
// eslint-disable-next-line
import { store } from './store/store';
import './css/index.css';
import './registerServiceWorker';
import {
  pluralize,
  formatDate,
  formatTimestamp,
  formatRuntime,
  zeroPad,
} from './filters';
import 'flag-icon-css/css/flag-icon.css';

// Filter
Vue.filter('pluralize', pluralize);
Vue.filter('formatDate', formatDate);
Vue.filter('formatTimestamp', formatTimestamp);
Vue.filter('formatRuntime', formatRuntime);
Vue.filter('zeroPad', zeroPad);

// Directives
Vue.use(Tooltip, {
  class: 'tooltip-custom',
});
Vue.use(VueMasonry);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
