import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';

Vue.use(Vuex);

export const state = {
  isLoading: false,
  pendingRequestCount: 0,
  filterFindingEvent: {},
};

export const getters = {
  isLoading: ({ isLoading }) => isLoading,
  pendingRequestCount: ({ pendingRequestCount }) => pendingRequestCount,
  filterFindingEvent: ({ filterFindingEvent }) => filterFindingEvent,
};

export const mutations = {
  UPDATE_IS_LOADING: (state, payload) => {
    state.isLoading = payload;
  },
  UPDATE_PENDING_REQUEST_COUNT: (state, payload) => {
    state.pendingRequestCount += payload;
  },
  UPDATE_FILTER_FINDING_EVENT: (state, payload) => {
    state.filterFindingEvent = { payload, timestamp: new Date() };
  },
};

export const actions = {
  addToFilterFinding: ({ commit }, finding) => {
    if (finding !== '') {
      commit('UPDATE_FILTER_FINDING_EVENT', finding);
    }
  },
};

export const store = new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules,
});
