<template>
    <header v-if="showHeader"
            class="relative bg-black border-b border-gray-600
                   md:flex
                   print:bg-white print:border-0 print:hidden">
      <div class="flex items-center justify-between print:hidden">
        <router-link
          :to="{ name: 'home' }"
          class="px-4">
          <Logo class="h-4" />
        </router-link>
        <Button
          type="text"
          @onClick="isOpen = !isOpen"
          class="text-white md:hidden">
          {{ isOpen ? 'Close' :  'Menu' }}
        </Button>
      </div>
      <!-- Print Version -->
      <div class="hidden items-center justify-between
                  print:flex">
        <div class="flex items-center">
          <DarkLogo class="h-8" />
        </div>
        <div class="text-gray-700 font-semibold">
          Report {{ String(currentReportID) | zeroPad(4) }} -
          {{ isDomain ? 'Domain' : 'Executive' }} Report
        </div>
      </div>
      <!-- End Print Version -->
      <Navigation :class="isOpen ? 'flex' : 'hidden'" class="mt-4 md:mt-0 md:flex" />
    </header>
</template>

<script>
import Button from '@/components/Button.vue';
import Logo from '@/assets/orbital_logo.svg?inline';
import DarkLogo from '@/assets/orbital_logo_dark.svg?inline';
import Navigation from '@/components/Navigation.vue';

export default {
  name: 'Header',
  components: {
    Button,
    Logo,
    DarkLogo,
    Navigation,
  },
  data() {
    return {
      isOpen: false,
      headerBlacklist: ['login', 'register', 'signup'],
    };
  },
  computed: {
    showHeader() {
      return this.headerBlacklist.indexOf(this.$route.name) === -1;
    },
    currentReportID() {
      return this.$route.params.execReport;
    },
    isDomain() {
      return this.$route.params.domainReport;
    },
  },
};
</script>

<style scoped>

</style>
