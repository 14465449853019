<template>
  <div>
    <Button v-if="!exceedsMaximumBillableAmount"
            type="primary"
            :inFlight="isLoading"
            class="flex-1 flex-col items-center justify-center px-4 py-4 bg-orange-500 w-40"
            @onClick="createSubscription">
      <div>
        <p class="whitespace-nowrap flex-1">{{label}}</p>
        <p class="flex-1">${{ price }}</p>
      </div>
    </Button>
    <a v-else :href="href">
      <Button
              type="primary"
              :inFlight="isLoading"
              class="flex-1 flex-col items-center justify-center px-4 py-4 bg-orange-500 w-40">
        <div>
          <p class="whitespace-nowrap flex-1">{{label}}</p>
          <p class="flex-1">${{ price }}</p>
        </div>
      </Button>
    </a>
    <p v-if="exceedsMaximumBillableAmount">Contact Us</p>
  </div>

</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Button from '@/components/Button.vue';

export default {
  name: 'SubscribeButton',
  props: ['price', 'interval', 'label'],
  components: { Button },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters(['prices']),
    exceedsMaximumBillableAmount() {
      return Number(this.price.replace(',', '')) >= 50000;
    },
    orgName() {
      return this.prices.org;
    },
    href() {
      return `mailto:orbital@phobos.io?subject=${this.orgName}-${this.reportId}-${this.label}-${this.price}`;
    },
    reportId() {
      return this.prices.reportId;
    },
  },
  methods: {
    ...mapActions(['getSubscriptionUrl']),
    async createSubscription() {
      this.isLoading = true;
      await this.getSubscriptionUrl(this.interval, '_blank');
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
</style>
