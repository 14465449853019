<template>
  <div class="flex justify-center sm:justify-start items-center self-stretch"
       v-click-outside:ariaExpanded="close">
    <Button id="reports-button"
            type="text-small"
            class="text-gray-300"
            ref="reportMenuToggle"
            @onClick="toggleMenu">
      Reports
      <template v-slot:iconRight>
        <Chevron class="mt-1"/>
      </template>
    </Button>
    <template v-if="currentReportID">
      <span class="px-3 text-gray-500">/</span>
      <router-link class="flex items-center router-link--no-glow"
        :to="{ name: 'executive-report',
          params: {
            execReport: currentReportID,
          },
        }">
        <span class="text-xs uppercase font-semibold tracking-wider"
              :class="[
                {'text-white': !currentDomain},
                {'text-gray-300': currentDomain},
              ]">
          Report {{ currentReportID | zeroPad(4) }}
        </span>
      </router-link>
      <template v-if="currentDomain">
        <span class="px-3 text-gray-500">/</span>
        <span class="text-gray text-xs uppercase font-semibold tracking-wider">
          {{ currentDomain }}
        </span>
      </template>
    </template>
    <transition name="slide-down"
                v-on:after-enter="focusSelectedItem">
      <ul v-show="ariaExpanded"
          class="report-list"
          role="menu"
          ref="menu"
          :style="{'height': menuHeight}"
          @keydown.down.stop.prevent="moveDown()"
          @keydown.up.stop.prevent="moveUp()"
          @keydown.tab="close"
          @keydown.esc="close"
          key="report-menu">
        <li class="p-3">
          <TextInput id="report-search"
                     type="text"
                     placeholder="Search reports..."
                     :hasBlackCorner="true"
                     v-model="searchValue"
                     @keyup.native="handleSearch" />
        </li>
        <li v-for="(report, index) in reports"
            :key="`report-list-item${index}`"
            @click="close()"
            class="border-b border-gray-800"
            :class="{'border-t': index === 0}">
          <router-link
            :to="{ name: 'executive-report', params: {
              execReport: report.id,
            }}"
            @keydown.space.native.prevent="handleReportItemClick(report.id)"
            class="flex relative group px-4 outline-none pt-2 pb-2
                   hover:bg-gray-800
                   transition-colors duration-150 ease-in-out"
            role="menuitem"
            tabindex="-1">
            <div class="inline-flex flex-col flex-shrink-0">
              <div class="mr-2 uppercase font-semibold text-xs tracking-wider"
                    :class="[
                {'text-blue-100': report.status === 'completed'},
                {'in-flight': report.status === 'in_flight'},
                {'text-red-600': report.status === 'failed'}
              ]">Report {{ report.id }}</div>
              <div class="flex leading-3">
                <div class="inline-flex justify-center items-center mr-2
                             text-gray-100 uppercase font-semibold text-xs tracking-wider">
                  <div>{{ report.findingsCount }}</div>
                  <FindingsIcon class="h-4 w-4" />
                </div>
                <div class="inline-flex items-center mr-2
                             text-gray-100 uppercase font-semibold text-xs tracking-wider">
                  <div>{{ report.attackPathwayFindingsCount }}</div>
                  <AttackPathwaysIcon class="inline-block h-4 w-4" />
                </div>
              </div>
            </div>
            <div class="inline-flex flex-wrap items-start">
              <span
                    class="report-input text-gray-300 uppercase font-semibold text-xs tracking-wider
                           mr-2
                           group-hover:text-white group-focus:text-white
                           transition-colors duration-150 ease-in-out">
                    {{ getReportLabel(report) }}
              </span>
              <ArrowRight class="opacity-0 text-gray-300 transform -translate-x-1 h-3 mt-1 -ml-1
                                 group-hover:opacity-100 group-focus:opacity-100
                                 group-hover:translate-x-0 group-focus:translate-x-0
                                 transition duration-150 ease-in-out" />
            </div>
          </router-link>
        </li>
        <li v-if="!reports.length">
          <h2 class="uppercase font-medium tracking-widest text-lg text-gray-200 text-center">
            No Reports matching search
          </h2>
        </li>
        <li v-if="reportsCount - (RETURNED_REPORTS_COUNT * currentReportsPage) > 0"
            class="flex justify-center my-4">
          <Button role="menuitem"
                  type="text-small"
                  tabindex="-1"
                  @onClick="handleLoadMore">
            Load More Reports
          </Button>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import clickOutside from '@/directives/clickOutsideHandler';
import ArrowRight from '@/assets/arrow_right.svg?inline';
import Button from '@/components/Button.vue';
import Chevron from '@/assets/chevron.svg?inline';
import TextInput from '@/components/TextInput.vue';
import AttackPathwaysIcon from '@/assets/attackpathway--noglow.svg?inline';
import FindingsIcon from '@/assets/warning--noglow.svg?inline';

const RETURNED_REPORTS_COUNT = 25;

export default {
  name: 'ReportsMenu',
  components: {
    ArrowRight,
    AttackPathwaysIcon,
    Button,
    Chevron,
    FindingsIcon,
    TextInput,
  },
  data() {
    return {
      ariaExpanded: false,
      menuItems: [],
      menuHeight: '0px',
      selectedIndex: 0,
      searchValue: '',
      currentReportsPage: 1,
      RETURNED_REPORTS_COUNT,
    };
  },
  directives: {
    clickOutside,
  },
  mounted() {
    this.populateMenuItems();
  },
  computed: {
    ...mapGetters(['reports', 'executiveReport', 'reportsCount']),
    currentReportID() {
      return this.$route.params.execReport;
    },
    currentDomain() {
      return (
        this.$route.params.domainReport
        && this.executiveReport
        && this.executiveReport.reportInput[0]
      );
    },
  },
  methods: {
    ...mapActions(['fetchReports', 'fetchReportsNonBlocking']),
    handleReportItemClick(reportID) {
      this.$router.push({
        name: 'executive-report',
        params: {
          execReport: reportID,
        },
      });
    },
    getReportLabel(report) {
      if (report.name) return report.name;
      if (!report.reportInput || report.reportInput.length <= 0) return 'Unknown';

      return report.reportInput.join(' ');
    },
    toggleMenu() {
      return this.ariaExpanded ? this.close() : this.open();
    },
    open() {
      this.fetchReportsNonBlocking();
      this.ariaExpanded = true;
      this.setMenuHeight();
      // focusSelectedItem() is called after height transition ends [See transition tag in template]
      window.document.body.style.height = '100vh';
      window.document.body.style.overflow = 'hidden';
    },
    close() {
      this.ariaExpanded = false;
      this.setMenuHeight();
      if (this.$refs.reportMenuToggle) {
        this.$refs.reportMenuToggle.$el.focus();
      }
      window.document.body.style.height = 'auto';
      window.document.body.style.overflow = 'auto';
    },
    moveDown(increment = 1) {
      this.selectedIndex = this.mod(
        this.selectedIndex + increment,
        this.menuItems.length,
      );
      this.focusSelectedItem();
    },
    moveUp(increment = -1) {
      this.moveDown(increment);
    },
    /**
     * Allows for modulo of negative numbers so selectedIndex can wrap back around to end of array
     */
    mod(dividend, divisor) {
      return ((dividend % divisor) + divisor) % divisor;
    },
    focusSelectedItem() {
      if (this.menuItems.length <= 0) return;

      this.menuItems[this.selectedIndex].focus();
      this.menuItems[this.selectedIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    },
    populateMenuItems() {
      this.$nextTick(() => {
        if (this.$refs.menu) {
          this.menuItems = Array.prototype.slice.call(
            this.$refs.menu.querySelectorAll('[role=menuitem]'),
          );
        }
      });
    },
    setMenuHeight() {
      this.$nextTick(() => {
        if (this.$refs.menu) {
          if (this.ariaExpanded) {
            const menuHeight = window.innerHeight - this.$refs.menu.getBoundingClientRect().top;
            this.menuHeight = `${menuHeight}px`;
          } else {
            this.menuHeight = '0px';
          }
        }
      });
    },
    handleSearch() {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.currentReportsPage = 1;
        this.fetchReports({
          searchValue: this.searchValue,
        });
      }, 500);
    },
    handleLoadMore() {
      this.currentReportsPage += 1;
      this.fetchReports({
        searchValue: this.searchValue,
        page: this.currentReportsPage,
      });
    },
  },
  watch: {
    $route() {
      this.close();
    },
    reports() {
      this.populateMenuItems();
    },
  },
};
</script>

<style scoped>
.report-list {
  @apply absolute bg-black w-screen left-0 z-50 max-h-screen overflow-y-scroll;
  top: calc(100% + 1px);
}

.router-link-active::after {
  @apply absolute left-0 bg-blue-100;
  top: 0.75rem;
  width: 2px;
  height: calc(100% - 1.5rem);
  content: "";
}

.router-link-active > .report-input {
  @apply text-white;
}

.router-link--no-glow::after {
  @apply hidden;
}

.in-flight {
  @apply text-center text-gray-300;
  background: linear-gradient(
    to right,
    #8a8888 10%,
    #5b5858 40%,
    #5b5858 60%,
    #8a8888 90%
  );
  background-size: 200% auto;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: in-flight-shine 2s linear infinite;
}

@keyframes in-flight-shine {
  to {
    background-position: 200% center;
  }
}

.slide-down-enter-active,
.slide-down-leave-active {
  transition: height 0.2s ease-out;
}

.slide-down-enter,
.slide-down-leave-to {
  height: 0px;
}
</style>
