import axios from 'axios';
import humps from 'humps';
import { constants } from '../config';

const { URL } = constants;

const api = axios.create({
  baseURL: URL,
  transformResponse: [
    ...axios.defaults.transformResponse,
    (data) => ({ ...humps.camelizeKeys(data), rawResponseData: data }),
  ],
  transformRequest: [
    (data, headers) => {
      data = humps.decamelizeKeys(data);
      // If we don't explicitly set the Content-Type header, we want to change it to
      // 'application/json' because axios will change it to 'application/json;charset=UTF-8'
      const accessToken = localStorage.getItem('orbital-access-token');
      if (!headers['Content-Type']) {
        headers['Content-Type'] = 'application/json';
      }
      if (accessToken) {
        headers.Authorization = `Token ${accessToken}`;
        // headers['Access-Control-Allow-Origin'] = true;
      }
      return data;
    },
    ...axios.defaults.transformRequest,
  ],
});

export default api;
