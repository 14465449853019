export default (to, from, next) => {
  const queryParamToken = to.query.token;
  if (queryParamToken) {
    localStorage.setItem('totp-challenge-token', queryParamToken);
  }
  setTimeout(() => {
    const token = Boolean(localStorage.getItem('totp-challenge-token'));
    if (token) return next();

    return next({ name: 'login' });
  }, 1500);
};
