var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"inline-block font-semibold tracking-wider text-sm relative text-center appearance-none cursor-pointer uppercase focus:outline-none transition-colors duration-100 ease-in",class:[
          { primary: _vm.type === 'primary' && !_vm.disabled },
          { text: _vm.type === 'text' && !_vm.disabled },
          { 'is-loading': _vm.inFlight },
          { 'text-small': _vm.type === 'text-small' && !_vm.disabled },
          { 'secondary': _vm.type === 'secondary' && !_vm.disabled },
          { 'secondary-orange': _vm.type === 'secondary-orange' && !_vm.disabled },
          { 'secondary-blue': _vm.type === 'secondary-blue' && !_vm.disabled },
          { disabled: _vm.disabled },
        ],attrs:{"disabled":_vm.disabled || _vm.inFlight},on:{"click":function($event){$event.stopPropagation();return _vm.click.apply(null, arguments)}}},[_c('div',{staticClass:"flex items-center justify-center pointer-events-none",class:{ 'opacity-0': _vm.inFlight }},[(_vm.hasIconLeft)?_c('div',{staticClass:"mr-2"},[_vm._t("iconLeft")],2):_vm._e(),_vm._t("default"),(_vm.hasIconRight)?_c('div',{staticClass:"ml-2"},[_vm._t("iconRight")],2):_vm._e()],2),(_vm.inFlight)?_c('div',{staticClass:"loading"},[_c('span'),_c('span'),_c('span')]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }