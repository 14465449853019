export default {
  URL: process.env.VUE_APP_API_URL,
  authConfig: {
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    audience: process.env.VUE_APP_AUTH_AUDIENCE,
    redirectUri: process.env.VUE_APP_AUTH_REDIRECT_URI,
  },
  findings: {
    GENERIC: 'generic',
  },
  H_CAPTCH_KEY: process.env.VUE_APP_H_CAPTCHA_KEY,
};
