import qs from 'qs';
import humps from 'humps';
import router from '@/router';
import api from '../api';

function arraysEqual(a = [], b = []) {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  const sorter = (a, b) => {
    if (a.username > b.username) {
      return -1;
    }
    if (a.username < b.username) {
      return 1;
    }
    return 0;
  };
  const aSorted = a.sort(sorter);
  const bSorted = b.sort(sorter);

  return JSON.stringify(aSorted) === JSON.stringify(bSorted);

  // return aSorted.every((val, idx) => val === bSorted[idx]);
}

const state = {
  executiveReport: null,
  pastReport: null,
  newReportError: null,
  reports: [],
  reportsCount: 0,
  reportScreenshots: [],
  customReportIsLoading: false,
  reportDeleteIsLoading: false,
  reportRecollateIsLoading: false,
  requestAdditionalDomainsStatus: null,
  requestDirectoryEnumerationStatus: null,
  adminViewActive: false,
  selectedView: 'superuser',
  pastScreenshots: [],
  newCustomReportEvent: { report: null, dt: null },
};

const getters = {
  adminViewActive: ({ adminViewActive }) => adminViewActive,
  currentReportInitiator: ({ executiveReport }) => {
    const hasInitiatedBy = executiveReport && executiveReport.initiatedBy;
    if (hasInitiatedBy) {
      // Try the name, otherwise email
      if (executiveReport.initiatedBy.firstName
        || executiveReport.initiatedBy.lastName) {
        if (!executiveReport.initiatedBy.firstName) {
          return executiveReport.initiatedBy.lastName;
        }
        if (!executiveReport.initiatedBy.lastName) {
          return executiveReport.initiatedBy.firstName;
        }
        return `${executiveReport.initiatedBy.firstName} ${executiveReport.initiatedBy.lastName}`;
      }
      return executiveReport.initiatedBy.email;
    }
    return null;
  },
  selectedView: ({ selectedView }) => selectedView,
  selectedViewParams: ({ selectedView }) => {
    if (selectedView === 'customer') return { is_customer_view: 1 };
    return {};
  },
  executiveReport: ({ executiveReport }) => executiveReport,
  pastReport: ({ pastReport }) => pastReport,
  newReportError: ({ newReportError }) => newReportError,
  reports: ({ reports }) => reports,
  reportScreenshots: ({ reportScreenshots }) => reportScreenshots,
  pastScreenshots: ({ pastScreenshots }) => pastScreenshots,
  reportsCount: ({ reportsCount }) => reportsCount,
  customReportIsLoading: ({ customReportIsLoading }) => customReportIsLoading,
  newCustomReportEvent: ({ newCustomReportEvent }) => newCustomReportEvent,
  reportDeleteIsLoading: ({ reportDeleteIsLoading }) => reportDeleteIsLoading,
  reportRecollateIsLoading: ({ reportRecollateIsLoading }) => reportRecollateIsLoading,
  requestAdditionalDomainsStatus: ({ requestAdditionalDomainsStatus }) => requestAdditionalDomainsStatus, // eslint-disable-line
  requestDirectoryEnumerationStatus: ({ requestDirectoryEnumerationStatus }) => requestDirectoryEnumerationStatus,  // eslint-disable-line
  rawDataForHostByIPAddress: ({ executiveReport }) => (ipAddress) => {
    let host = null;
    if (executiveReport
        && executiveReport.rawResponseData
        && executiveReport.rawResponseData.aggregated_result
        && executiveReport.rawResponseData.aggregated_result.ips
        && executiveReport.rawResponseData.aggregated_result.ips.length > 0) {
      host = executiveReport.rawResponseData.aggregated_result.ips.find(
        (ip) => ip.ip === ipAddress,
      ) || null;
    }

    return host;
  },
  noNewCreds: ({ executiveReport, pastReport }) => (pastReport?.summary?.leakedCredentials
        && executiveReport?.summary?.leakedCredentials
    ? arraysEqual(executiveReport?.summary?.leakedCredentials, pastReport?.summary?.leakedCredentials)// eslint-disable-line
    : false),
};

const mutations = {
  CLEAR_REPORT_STATE: (state, payload) => {
    if (!payload) return;
    state.executiveReport = null;
    state.newReportError = null;
    state.reports = [];
  },
  CLEAR_EXECUTIVE_REPORT: (state, payload) => {
    if (!payload) return;
    state.executiveReport = null;
  },
  UPDATE_EXEC_REPORT: (state, payload) => {
    state.executiveReport = payload;
  },
  UPDATE_EXEC_REPORT_DIRECTORY_ENUMERATION_STATUS_INFLIGHT: (state) => {
    state.executiveReport.manualDirectoryEnumerationStatus = 'in_flight';
    state.executiveReport.manualDirectoryEnumerationTimestamp = new Date();
  },
  UPDATE_SCREENSHOT_REPORT: (state, payload) => {
    state.reportScreenshots = payload;
  },
  UPDATE_PAST_REPORT: (state, payload) => {
    state.pastReport = payload;
  },
  UPDATE_PAST_SCREENSHOTS: (state, payload) => {
    state.pastScreenshots = payload;
  },
  UPDATE_NEW_REPORT_ERROR: (state, payload) => {
    if (payload && payload.response && payload.response.data) {
      state.newReportError = payload.response.data;
    } else {
      state.newReportError = payload;
    }
  },
  UPDATE_REPORTS_COUNT: (state, payload) => {
    state.reportsCount = payload;
  },
  UPDATE_REPORTS: (state, payload) => {
    if (payload.isAppending) {
      state.reports = [...state.reports, ...payload.data];
    } else {
      state.reports = payload.data;
    }
  },
  ADD_REPORT: (state, payload) => {
    const oldReports = [...state.reports];
    oldReports.unshift({
      id: payload.id,
      status: payload.status,
      reportInput: payload.reportInput,
    });
    state.reports = oldReports;
  },
  REMOVE_REPORT: (state, payload) => {
    const reports = state.reports.filter((r) => r.id !== payload);

    state.reports = reports;
  },
  UPDATE_IS_REPORT_DELETE_LOADING: (state, payload) => {
    state.reportDeleteIsLoading = payload;
  },
  UPDATE_IS_REPORT_RECOLLATING_LOADING: (state, payload) => {
    state.reportRecollateIsLoading = payload;
  },
  UPDATE_CUSTOM_REPORT_IS_LOADING: (state, payload) => {
    state.customReportIsLoading = payload;
  },
  UPDATE_REQUEST_ADDITIONAL_DOMAINS_STATUS: (state, payload) => {
    state.requestAdditionalDomainsStatus = payload;
  },
  UPDATE_REQUEST_DIRECTORY_ENUMERATION_STATUS: (state, payload) => {
    state.requestDirectoryEnumerationStatus = payload;
  },
  UPDATE_ADMIN_VIEW_ACTIVE: (state, payload) => {
    state.adminViewActive = payload;
  },
  UPDATE_SELECTED_VIEW: (state, payload) => {
    state.selectedView = payload;
  },
  ADD_CUSTOM_REPORT: (state, {
    id, name, reportInput, status,
  }) => {
    state.reports.push({
      attackPathwayFindingsCount: 0,
      findingsCount: 0,
      id,
      isCustomExecutive: true,
      name,
      reportInput,
      status,
    });
    state.newCustomReportEvent = { report: id, dt: new Date() };
  },
};

const actions = {
  fetchReports: async ({ commit, getters }, queries) => {
    const finalQuery = {};

    if (queries) {
      if (queries.page) {
        finalQuery.page = queries.page;
      }
      if (queries.searchValue) {
        finalQuery.report_input_include = queries.searchValue;
      }
    } else {
      finalQuery.page = 1;
    }

    const shouldRefetchWithLoadingSpinner = !(getters.reportsCount > 0);

    if (shouldRefetchWithLoadingSpinner) {
      commit('UPDATE_IS_LOADING', true);
    }

    try {
      const data = await api.get(`reports/?page=${finalQuery.page}`);
      commit('UPDATE_REPORTS', {
        data: data.data.results,
        isAppending: queries && queries.page,
      });

      commit('UPDATE_REPORTS_COUNT', data.data.count);
    } catch (e) {
      commit('CLEAR_REPORT_STATE', []);
    }
    commit('UPDATE_IS_LOADING', false);

    if (getters.reports.length > 0) {
      const newestReport = getters.reports.find((r) => r.status !== 'failed');

      if (newestReport) {
        if (!router.currentRoute.params.execReport) {
          await router.push({
            name: 'executive-report',
            params: {
              execReport: newestReport.id,
            },
          });
        }
      } else {
        await router.push({
          name: 'new-report',
        });
      }
    }
  },
  fetchReportsNonBlocking: async ({ commit }, queries) => {
    const finalQuery = {};

    if (queries) {
      if (queries.page) {
        finalQuery.page = queries.page;
      } else {
        finalQuery.page = 1;
      }
      if (queries.searchValue) {
        finalQuery.report_input_include = queries.searchValue;
      }
    }

    try {
      const data = await api.get(`reports/?${qs.stringify(finalQuery, { indices: false })}`);
      commit('UPDATE_REPORTS', {
        data: data.data.results,
        isAppending: queries && queries.page,
      });

      commit('UPDATE_REPORTS_COUNT', data.data.count);
    } catch (e) {
      commit('CLEAR_REPORT_STATE', []);
    }
  },
  fetchReportAsync: async ({ commit, getters }, report) => {
    commit('UPDATE_IS_LOADING', true);
    try {
      const data = await api.get(`reports/${report}/`, {
        headers: {},
        params: getters.selectedViewParams,
      });
      commit('UPDATE_EXEC_REPORT', data.data);
      commit('UPDATE_PAST_REPORT', null);
    } catch {
      commit('CLEAR_REPORT_STATE', []);
    }
    commit('UPDATE_IS_LOADING', false);
  },

  fetchReport: ({ commit, getters }, report) => {
    commit('UPDATE_IS_LOADING', true);
    api.get(`reports/${report}/`, {
      headers: {},
      params: getters.selectedViewParams,
    })
      .then((data) => {
        commit('UPDATE_EXEC_REPORT', data.data);
      })
      .catch(() => {
        commit('CLEAR_REPORT_STATE', []);
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false);
      });
  },

  fetchReportScreenshotsAsync: async ({ commit, getters }, screenshotReportId) => {
    commit('UPDATE_IS_LOADING', true);
    try {
      const data = await api.get(`reports/${screenshotReportId}/screenshots/`, {
        headers: {},
        params: getters.selectedViewParams,
      });
      commit('UPDATE_SCREENSHOT_REPORT', humps.camelizeKeys(data.data.rawResponseData));
    } catch {
      commit('CLEAR_REPORT_STATE', []);
    }
    commit('UPDATE_IS_LOADING', false);
  },

  fetchReportScreenshots: ({ commit, getters }, screenshotReportId) => {
    commit('UPDATE_IS_LOADING', true);
    api.get(`reports/${screenshotReportId}/screenshots/`, {
      headers: {},
      params: getters.selectedViewParams,
    })
      .then((data) => {
        commit('UPDATE_SCREENSHOT_REPORT', humps.camelizeKeys(data.data.rawResponseData));
      })
      .catch(() => {
        commit('CLEAR_REPORT_STATE', []);
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false);
      });
  },

  fetchExecReport: ({ commit, getters }, executiveReportId) => {
    commit('UPDATE_IS_LOADING', true);
    api.get(`reports/${executiveReportId}/executive-summary/`, {
      headers: {},
      params: getters.selectedViewParams,
    })
      .then((data) => {
        commit('UPDATE_EXEC_REPORT', data.data);
      })
      .catch(() => {
        commit('CLEAR_REPORT_STATE', []);
      })
      .finally(() => {
        commit('UPDATE_IS_LOADING', false);
      });
  },

  setAdminViewActive: ({ commit }, isAdminActive) => {
    commit('UPDATE_ADMIN_VIEW_ACTIVE', isAdminActive);
  },

  setSelectedView: ({ commit }, selectedView) => {
    commit('UPDATE_SELECTED_VIEW', selectedView);
  },

  fetchPastReportScreenshots: async ({ commit, getters }, screenshotReportId) => {
    try {
      commit('UPDATE_IS_LOADING', true);
      const data = await api.get(`reports/${screenshotReportId}/screenshots/`, {
        headers: {},
        params: getters.selectedViewParams,
      });
      commit('UPDATE_PAST_SCREENSHOTS', data.data.rawResponseData);
    } finally {
      commit('UPDATE_IS_LOADING', false);
    }
  },

  fetchPastReport: async ({ commit }, report) => {
    commit('UPDATE_IS_LOADING', true);
    try {
      const data = await api.get(`reports/${report}/`);
      commit('UPDATE_PAST_REPORT', data.data);
    } catch {
      commit('CLEAR_REPORT_STATE', []);
    } finally {
      commit('UPDATE_IS_LOADING', false);
    }
  },

  submitNewReport: ({ commit }, reportInput) => {
    commit('UPDATE_IS_LOADING', true);
    return api.post('reports/', {
      reportInput,
    })
      .then((data) => {
        commit('ADD_REPORT', data.data);
        commit('UPDATE_EXEC_REPORT', data.data);
        return data;
      })
      .catch((error) => {
        commit('CLEAR_REPORT_STATE', []);
        commit('UPDATE_NEW_REPORT_ERROR', error);
      }).finally(() => {
        commit('UPDATE_IS_LOADING', false);
      });
  },

  updateNewReportError: ({ commit }, error) => {
    commit('UPDATE_NEW_REPORT_ERROR', error);
  },

  deleteReport: ({ commit }, reportId) => {
    commit('UPDATE_IS_REPORT_DELETE_LOADING', true);
    return api.delete(`reports/${reportId}/`, {
      incrementRequestCount: false,
    })
      .then(() => {
        commit('REMOVE_REPORT', reportId);
      })
      .catch(() => commit('CLEAR_REPORT_STATE', []))
      .finally(() => {
        commit('UPDATE_IS_REPORT_DELETE_LOADING', false);
      });
  },

  recollateReport: ({ commit }, reportId) => {
    commit('UPDATE_IS_REPORT_RECOLLATING_LOADING', true);
    return api.post(`reports/${reportId}/recollate/`, null, {
      incrementRequestCount: false,
    })
      .catch(() => commit('CLEAR_REPORT_STATE', []))
      .finally(() => {
        commit('UPDATE_IS_REPORT_RECOLLATING_LOADING', false);
      });
  },

  createCustomReport: ({ commit }, customReport) => {
    commit('UPDATE_CUSTOM_REPORT_IS_LOADING', true);
    return api.post('reports/custom/', customReport, { incrementRequestCount: false })
      .then(({ data }) => {
        commit('ADD_CUSTOM_REPORT', data);
        return data;
      })
      .finally(() => {
        commit('UPDATE_CUSTOM_REPORT_IS_LOADING', false);
      });
  },

  requestAdditionalDomains: ({ commit }, { domains, report }) => {
    commit('UPDATE_REQUEST_ADDITIONAL_DOMAINS_STATUS', 'loading');
    return api.post(`/reports/${report}/apex-domains/`, {
      domains,
    }, {
      incrementRequestCount: false,
    })
      .then(() => commit('UPDATE_REQUEST_ADDITIONAL_DOMAINS_STATUS', 'success'))
      .catch(() => commit('UPDATE_REQUEST_ADDITIONAL_DOMAINS_STATUS', 'error'));
  },

  updateRequestAdditionalDomainStatus: ({ commit }, status) => {
    commit('UPDATE_REQUEST_ADDITIONAL_DOMAINS_STATUS', status);
  },

  requestDirectoryEnumeration: ({ commit }, { hosts, report }) => {
    commit('UPDATE_REQUEST_DIRECTORY_ENUMERATION_STATUS', 'loading');
    return api.post(`/reports/${report}/directory_enumeration/`, {
      target: hosts,
    }, {
      incrementRequestCount: false,
    })
      .then(() => commit('UPDATE_REQUEST_DIRECTORY_ENUMERATION_STATUS', 'success'))
      .then(() => commit('UPDATE_EXEC_REPORT_DIRECTORY_ENUMERATION_STATUS_INFLIGHT'))
      .catch(() => commit('UPDATE_REQUEST_DIRECTORY_ENUMERATION_STATUS', 'error'));
  },

  updateRequestDirectoryEnumerationStatus: ({ commit }, status) => {
    commit('UPDATE_REQUEST_DIRECTORY_ENUMERATION_STATUS', status);
  },
};

export default {
  actions,
  state,
  getters,
  mutations,
};
